export default {
  /** ----------------------------------------------------
   *                        ANCV
   *  ---------------------------------------------------- */
  title_pay_with_ancv: "Paga con tu cuenta de Chèque-Vacances Connect",
  try_again: "El pago a través de Chèque-Vacances falló. Por favor, inténtelo de nuevo",
  important: "Importante: ",
  ancv_app_mandatory:
    "La aplicación de Chèque-Vacances es necesaria para validar este pago",
  ancv_id: "Tu ID de Chèque-Vacances Connect",
  open_ancv_app:
    "Abre tu aplicación ANCV Chèque-Vacances para completar el proceso de pago",
  transaction_success: "Su transacción ha sido registrada con éxito",
  transaction_infos_below:
    "A continuación encontrará la información relacionada con su pago",
  see_you: "Nos vemos pronto",
  ancv_signature: "El equipo de la Agencia Nacional de Chèque-Vacances",
  ancv_amount: "Importe recibido",
  total_amount: "Cantidad solicitada",
  ancv_transaction_id: "Referencia de la transacción",
  remaining_amount: "Cantidad restante a pagar",
  excluding_fees: "excluyendo los gastos de financiación",
  installment_cta:
    "Pagar el importe restante en {installments_nb}x con tarjeta de crédito",
  deferred_cta: "Pagar más tarde el resto por tarjeta de crédito",
  infos_ancv_and_bnpl:
    "Una vez validado el importe a pagar en Chèque-Vacances mediante la aplicación de Chèque-Vacances, se le ofrecerá un calendario de pagos en función del importe restante a pagar con tarjeta de crédito.",
  warning_delay_bnpl:
    "Tiene {delayInMinutes} minutos para finalizar su pago. Después de este tiempo, el pago en Chèque-Vacances y su reserva serán cancelados.",
  /** ----------------------------------------------------
   *                        CHECKOUT
   *  ---------------------------------------------------- */
  input_bank_details: "Ingreso mis datos bancarios",
  amount_to_pay: "Importe a pagar",
  total_purchase_amount: "Importe total",
  split_explanation: "Si todos los participantes pagan en 48 horas, pagaré ",
  i_pay_my_share: "Pago mi parte",
  i_pay_my_installment: "Pago mi mensualidad",
  anticipated_payment_warning:
    "Se dispone a pagar el plazo que vence el <b>{date}</b>, es decir, <b>{days} días</b> antes de la fecha inicialmente acordada",
  i_change_my_payment_method: "Actualizar mi método de pago",
  payment_method_fingerprint_amount:
    "Es posible que aparezca en su cuenta una huella de su tarjeta bancaria por un monto de {amount}. Esto es temporal y no se le deduce ningún monto.",
  card_number: "Número de la tarjeta",
  expiration_date: "Fecha de caducidad",
  security_code: "Código de seguridad",
  card_network: "Red bancaria",
  notice_payment: "instrucciones de uso del servicio de pago",
  accept_cta_installment:
    "Al hacer clic en Validar, acepto {0}. Por último, certifico que la tarjeta utilizada para pagar mi compra está vinculada a una cuenta abierta a mi nombre.",
  split_default_message:
    "Recibiré una solicitud de autorización del importe total. No se cargará ninguna cantidad. Mis amigos tienen 48 horas para pagar. Después de este tiempo, si uno de ellos no paga, se me cobrará.",
  secured_payment: "pago seguro",
  deadline_on: "Fecha de pago:",
  with_fees_amount: "({amount} de intereses incluidos)",
  basket_amount: "Cesta:",
  ill_pay_later: "Pagaré el {date}",
  ill_pay_later_on_delivery:
    "Pagaré en el momento de la entrega (y a más tardar el {date})",
  penalties_detail:
    "incluyendo {penalties_percentage}% de penalizaciones por retraso: {amount}",
  not_supported_cards:
    "Por favor, ten en cuenta que no aceptamos: Tarjetas prepago, con autorización sistemática tipo Electron, Maestro, Nickel Account, Revolut, ni tampoco tarjetas virtuales o electrónicas.",
  payment_method_issue:
    "Se ha producido un error al utilizar su método de pago. Póngase en contacto con el equipo de asistencia del comerciante.",
  order_info_multiple_shipment:
    "El pedido puede constar de varios envíos. Cada envío tendrá su propio horario que comenzará en el momento del envío.",
  deposit_deadline_on: "Pago inicial el",
  balance_deadline_on: "Importe el",
  balance_to_pay_later: "Pagaré el importe el {date}",
  test_another_card: "Pruebe con otra tarjeta.",
  entry_error: "Error de introducción.",
  card_declined: "Su tarjeta ha sido rechazada.",
  invalid_iban: "Su iban es incorrecto.",
  stripe_library_not_loaded: "La biblioteca Stripe V3 no se ha cargado",
  taeg: "Contratas una línea de pago a {num_of_days} días, lo que supone una comisión de {amount} , es decir, una TAE del {taeg}%.",
  amount_paid_in_ancv: "Cantidad pagada en Chèque-Vacances",
  advertisement_optin: "Deseo recibir comunicaciones comerciales de {optin_advertiser}",
  electronic_optin:
    "Deseo recibir comunicaciones comerciales electrónicas de la CA Consumer Finance",
  phone_mail_optout:
    "No deseo recibir comunicaciones comerciales telefónicas o por correo de la CA Consumer Finance",
  contract_message_start: "Acepto la ",
  contract_message_link_installment:
    "información precontractual y contractual sobre el pago a plazos ",
  contract_message_link_deferred:
    "información precontractual y contractual sobre el pago aplazado ",
  contract_message_end:
    "y certifico que la tarjeta utilizada para pagar mi compra está vinculada a una cuenta abierta a mi nombre.",
  /** ----------------------------------------------------
   *                        COMMONS
   *  ---------------------------------------------------- */
  add: "Añadir",
  me: "Yo",
  friend: "Amigo",
  submit: "Validar",
  yes: "si",
  no: "No",
  update: "Actualizar",
  apply: "Aplicar",
  see_faq: "Consulte nuestras preguntas frecuentes para obtener más información",
  secure: "100% seguro (SSL)",
  unknown: "Desconocido",
  on_date: "El {date}",
  footer_question_to_merchant:
    "Para cualquier consulta relacionada con su compra, comuníquese directamente con {merchantName}.",
  footer_question_to_pledg:
    'Para cualquier información relacionada con su financiación, consulte nuestras <a href="{faqUrl}" target="_blank">FAQ</a> o escríbanos a <a href="mailto:{contactEmail}"> {contactEmail} </a>.',
  notice_access_link: "Aviso de uso del servicio de pago",
  data_policy_link:
    "<a href='{dataPolicyUrl}' target='_blank'>Política de privacidad de datos</a>",
  data_policy:
    "Mis datos serán utilizados en un proceso totalmente automatizado para conceder una facilidad de pago: <a href='{dataPolicyUrl}' target='_blank'>política de privacidad de datos</a>.",
  contact_access_link:
    "<a href='{contactUrl}' target='_blank'>Servicio de pago de contacto</a>",
  no_information: "No se proporcionó información",
  no_psp_source: "No hay método de pago disponible",
  redirected_from_message:
    "{merchantName} te ha redirigido a {operatorTeamName} para completar tu pago.",
  /** ----------------------------------------------------
   *                    DASHBOARD BUYER
   *  ---------------------------------------------------- */
  notice_infos: "Por favor, consulte el aviso de servicio de pago",
  error_invalid_contact: "El contacto no es válido",
  fees_amount: "(Tarifas: {amount} {currency})",
  here: "aquí",
  deferred_intro:
    "Estado de tu compra diferida de {date} a {hour} (hora de {timezone_city}) en {merchantName}.",
  installment_intro:
    "Estado de tu compra dividida de {date} a {hour} (hora de {timezone_city}) en {merchantName}.",
  split_intro:
    "Estado de su compra compartida de {date} a {hour} (hora de {timezone_city}) en {merchantName}.",
  penalties_amount: "(Penalizaciones: {amount} {currency})",
  proceed_payment_late: "Regularizar mi situación: {amount} {currency}",
  proceed_payment_in_advance: "Pagar mi próxima cuota: {amount} {currency}",
  dashboard_buyer_your_purchase: "Tu compra",
  dashboard_buyer_your_basket: "Su cesta",
  dashboard_buyer_your_fees: "Tarifa de financiación ({feesPercentage}%)",
  dashboard_buyer_payment_schedule: "Sus fechas de vencimiento",
  dashboard_buyer_products: "Sus productos",
  dashboard_buyer_see_schedule: "Ver los horarios",
  dashboard_buyer_overdue: "Impago",
  dashboard_buyer_overdue_payment: "Regularización de su situación",
  dashboard_buyer_overdue_payment_text:
    "El pago para la fecha de vencimiento de {date} ha fallado. Por favor proceda a regularizar su situación.",
  dashboard_buyer_your_payment_method: "Tu método de pago",
  dashboard_buyer_your_payment_method_expired_soon:
    "Tu método de pago está a punto de caducar. Actualízalo para evitar incidencias en el pago.",
  dashboard_buyer_your_payment_method_text_installment:
    "Los débitos de tus cuotas mensuales se realizan en las fechas acordadas utilizando el siguiente método de pago:",
  dashboard_buyer_your_payment_method_text_deferred:
    "El importe adeudado se cobrará en la fecha acordada, utilizando el siguiente método de pago:",
  dashboard_buyer_your_payment_method_button: "Cambiar mi método de pago",
  dashboard_buyer_share_captured_amount: "Pagado:",
  dashboard_buyer_share_not_charged_amount: "Cancelado:",
  dashboard_buyer_share_refunded_amount: "Reintegrado:",
  dashboard_buyer_share_outstanding_amount: "Importe pendiente:",
  dashboard_buyer_title_installment:
    'Su <span class="text-secondary">pago en {installmentsNb} veces</span> en {merchantName}',
  dashboard_buyer_title_deferred:
    'Su <span class="text-secondary">pago diferido</span> en {merchantName}',
  dashboard_buyer_title_down_payment:
    'Su <span class="text-secondary">pago con depósito</span> en {merchantName}',
  dashboard_buyer_title_split:
    'Su <span class="text-secondary">pago compartido</span> en {merchantName}',
  dashboard_buyer_status_total_refund:
    "Su compra ha sido reembolsada de acuerdo con los términos y condiciones de {merchantName} y el servicio de pago.",
  dashboard_buyer_status_split_waiting_payment:
    "No todos los participantes han pagado su parte.",
  dashboard_buyer_status_fully_paid:
    "Tu compra ha sido pagada en su totalidad. Nos vemos pronto en {merchantName}.",
  dashboard_buyer_status_overdue:
    "El pago de una cuota ha fallado. Regulariza tu situación o contacta con el servicio de pago lo antes posible.",
  dashboard_buyer_status_ongoing:
    "El pago de su compra se realiza normalmente, no se requiere ninguna acción por su parte.",
  dashboard_buyer_status_psp_source_expired:
    "Su método de pago está por vencer. Actualízalo para seguir pagando tus cuotas.",
  resend_message: "Reenviar mensaje",
  split_leader_label: "Tú",
  state_leader_not_paid: "Pendiente",
  state_expired_not_paid: "Pago fallido, a regularizar",
  state_future_not_paid: "Débito automático en la fecha de vencimiento",
  state_preauthorization_ok: "Impresión bancaria",
  state_sepa_debit_pending: "Débito SEPA pendiente de validación",
  state_sofort_pending: "Pago de Sofort pendiente",
  state_ancv_pending_transfer: "Esperando validación",
  state_ancv_processing: "A la espera del pago por Chèques-Vacances",
  state_ancv_preauthorization_ok: "Pagado con Chèques-Vacances",
  state_payment_ok: "Pagado",
  state_paid_by_leader: "Pagado por ti",
  state_unknown: "En espera de pago",
  state_fully_refunded: "Reintegrado",
  state_fully_not_charged: "Cancelado",
  split_explanation_1:
    "Cada participante tiene hasta el {date} a las {hour} ({timezone_city} time) para pagar su split. Después de este tiempo, se le cobrará el monto de su split ({amount} {currency}) como así como acciones no pagadas.",
  dashboard_order_link: "Volver al panel de pedidos",
  header_amount: "Cantidad",
  header_participants: "Participantes",
  header_status: "Estado",
  error_dashboard_title: "Panel no disponible",
  error_dashboard_subtitle: "No se pudo mostrar el resumen de su compra.",
  error_dashboard_reason_title: "¿Por qué motivo?",
  error_dashboard_reason_1: "Es posible que la compra en cuestión no esté disponible",
  error_dashboard_reason_1_details:
    "Este panel no está disponible para compras creadas o canceladas.",
  error_dashboard_reason_2: "Esta página puede haber expirado",
  error_dashboard_reason_2_details:
    "Por razones de seguridad, esta página está sujeta a vencimiento. Cierre la página y haga clic en el enlace original nuevamente",
  /** ----------------------------------------------------
   *                        ERRORS
   *  ---------------------------------------------------- */
  purchaseNotFound: "No hay compras asociadas a esta URL.",
  genericError:
    "Ocurrió un error. Si vuelve a ocurrir, contáctenos en contact{'@'}pledg.co.",
  error_infra_unavailable:
    "El servicio no está disponible en este momento. Vuelva a cargar la página o inténtelo más tarde.",
  notAddedValidDestinationWarning:
    "Haga clic en el botón '+ Agregar' para agregar a su amigo.",
  invalidDestination:
    "¡Este correo electrónico / número de teléfono móvil no es válido!",
  alreadyUsedDestination:
    "¡Este correo electrónico / número de teléfono móvil ya está en uso!",
  invalidAmount: "¡Esta cantidad no es válida!",
  closingWarning: "¿De verdad quieres cerrar esa ventana?",
  singleShareWarning: "¿De verdad quieres pagar solo?",
  Confirmation3DSTimeoutError: "La autenticación falló. Inténtalo de nuevo.",
  ancvConfirmationTimeoutError:
    "Error al pagar con Chèques-Vacances. Por favor, inténtalo de nuevo.",
  requiredPropertyMissingOrInvalid:
    "Falta la propiedad requerida {property} o no es válida",
  requiredPropertyMissing: "Falta la propiedad requerida {property}",
  propertyInvalid: "La propiedad proporcionada {property} no es válida",
  paymentRefused: "El pago ha sido rechazado",
  errorHappened:
    "<strong>Solicitud de facilidad de pago rechazada, por favor utilice otro método de pago.</strong>",
  errorAmountHold:
    "Dependiendo de su banco, es posible que se realice un cargo en su cuenta. Esto es temporal y no se cargará ningún importe en su cuenta.",
  retry_error: "Solicitud de facilidad de pago rechazada.",
  ineligibilityPaymentMethod: "Por favor, utilice otro método de pago",
  ineligibilityPaymentMethodRedirect:
    "Serás redirigido para elegir otro método de pago.",
  ineligibilityPaymentMethodRedirectWithMerchant:
    "Serás redirigido al sitio de {merchantName} para elegir otro método de pago.",
  ineligibilityErrorContentWithMerchant:
    "Basándonos en la información facilitada por {merchantName} o recogida directamente por el proveedor de pagos y tras un proceso de decisión totalmente automatizado, su solicitud no cumple los criterios de elegibilidad para este mecanismo de pago. Esta decisión no prejuzga el resultado de futuras solicitudes de financiación y puede ponerse en contacto con nosotros escribiendo a {infoEmail} para obtener más información.",
  ineligibilityErrorContent:
    "Sobre la base de la información recopilada directamente por el proveedor de pagos y tras un proceso de decisión totalmente automatizado, su solicitud no cumple los criterios de elegibilidad para este mecanismo de pago. Esta decisión no prejuzga el resultado de futuras solicitudes de financiación y puede ponerse en contacto con nosotros escribiendo a {infoEmail} para obtener más información.",
  provideMerchantUid: "Proporcione un UID de comerciante",
  invalidPhoneNumber: "Este número de teléfono no es válido.",
  IdentificationConfirmationTimeoutError:
    "Se produjo un error durante el proceso de verificación. Si vuelve a ocurrir, contáctenos en {contact_email}.",
  splitIncorrectAmounts: "Los importes introducidos parecen ser incorrectos",
  fipenDownloadError:
    "Se ha producido un error al recuperar su información precontractual. Vuelve a intentarlo más tarde.",
  contractDownloadError:
    "Se ha producido un error al recuperar su información contractual. Vuelve a intentarlo más tarde.",
  contractSignError:
    "Se ha producido un error al firmar tu contrato. Vuelva a intentarlo más tarde",
  /** ----------------------------------------------------
   *                        IDENTIFICATION
   *  ---------------------------------------------------- */
  identity_check: "Verificación de la identidad",
  identification_success: "La verificación fue exitosa",
  identification_failure: "La verificación ha fallado",
  identification_processing: "La verificación está en curso",
  identity_check_warning_message:
    "Está a punto de ser redirigido para verificar su identidad.",
  /** ----------------------------------------------------
   *                    INFORMATION MISSING
   *  ---------------------------------------------------- */
  address_street: "Dirección (número, calle, etc.)",
  address_zipcode: "Código postal",
  address_city: "Ciudad",
  civility: "Civismo",
  birth_last_name: "Nombre de nacimiento",
  madam: "Señora",
  mister: "Señor",
  address_province: "Región",
  address_country: "País",
  birth_date_with_format: "Fecha de nacimiento (YYYY-MM-DD)",
  birth_city: "Ciudad natal",
  birth_country: "País de nacimiento",
  search_birthplace: "Buscar un lugar de nacimiento",
  birth_place_autocomplete_error: "Por favor, seleccione un lugar de nacimiento válido",
  nationality: "Nacionalidad",
  phone_number: "Número de teléfono",
  missing_information_title: "Necesitamos información adicional",
  missing_information_subtitle:
    "Para proceder con su solicitud de financiación, necesitamos más información. ",
  missing_information_subtitle_payer: "Introduzca sus datos de facturación",
  missing_address_section: "Dirección postal",
  missing_phone_number_section: "Información de contacto",
  missing_birthday_section: "Fecha de nacimiento",
  missing_civility_explanation:
    "Identidad, tal como figura en su documento de identidad (estamos obligados a pedírselo).",
  missing_birthplace_section: "Lugar de nacimiento",
  firstname: "Nombre",
  lastname: "Apellido",
  email: "Correo electrónico",
  day: "Día",
  month: "Mes",
  year: "Año",
  fipen_text: "Ya puede descargar ",
  fipen_link: "su información precontractual.",
  /** ----------------------------------------------------
   *                    REVENUE
   *  ---------------------------------------------------- */
  title_revenue: "Necesitamos más información",
  subtitle_revenue_1: "Para calcular mi presupuesto mensual restante,",
  subtitle_revenue_2: "introduzco mis ingresos y gastos mensuales",
  remaining_budget_text: "Después de pagar mis gastos, mi presupuesto restante es:",
  declared_revenue: "Ingresos después de impuestos",
  declared_expenditure: "Gastos recurrentes",
  declared_csp: "Categoría profesional",
  tooltip_revenue: "Salario neto, ayuda financiera, ingresos inmobiliarios...",
  tooltip_expenditure: "Alquiler, préstamos...",
  bgv_text:
    "La información financiera facilitada no nos permite concederte la facilidad de pago. El uso de esta facilidad de pago le pondría en riesgo de sobreendeudamiento. Si desea conocer la situación, puede conectarse a la Web App BGV (Budget Grande Vitesse) ofrecida por nuestro socio Crésus haciendo clic en el siguiente enlace : ",
  back_to_merchant: "Entiendo, llévame de vuelta al comerciante",
  professional_categories: [
    "Gerentes ejecutivos",
    "Profesiones intelectuales y científicas",
    "Profesiones asalariadas intermedias",
    "Pequeños empresarios (no empleados)",
    "Empleados calificados",
    "Trabajadores calificados asalariados",
    "Profesiones asalariadas poco cualificadas",
    "Jubilado",
    "Otros desempleados",
  ],
  /** ----------------------------------------------------
   *                    INFORMATION SPLIT
   *  ---------------------------------------------------- */
  title_whodoisharewith: "¿Quiénes son los amigos con los que comparto mi pedido?",
  subtitle_addfriends:
    "Añado las direcciones de correo electrónico o los números móviles de mis amigos.",
  placeholder_friend_email_phone_number:
    "El correo electrónico o número de teléfono móvil de mi amigo/a",
  attendees: "Sin co-comprador | 1 co-comprador | {count} co-compradores",
  pay_alone: "Quiero pagar por mi cuenta",
  /** ----------------------------------------------------
   *                        PAYMENT SOLUTIONS
   *  ---------------------------------------------------- */
  payment_solutions_title: "Mis facilidades de pago",
  payment_solutions_subtitle: "en {company}",
  payment_solutions_choice_infos: "Seleccione la forma de pago que más le convenga",
  payment_schedule_installment_homogeneous: "{installments_nb}x {amount}",
  payment_schedule_installment_disparate:
    "{amount} después {installments_nb}x {second_amount}",
  payment_schedule_deferred_delay_in_days: "{amount} en {delay_in_days} dias",
  payment_schedule_deferred_day_of_next_month: "{amount} el {payment_date}",
  payment_solutions_no_merchants_found:
    "Ninguna solución de pago cumple los criterios de elegibilidad en función de la información facilitada",
  /** ----------------------------------------------------
   *                        PROCESSING
   *  ---------------------------------------------------- */
  payment_pending: "Pago pendiente",
  payment_pending_information_1:
    "Por favor, no actualice la página mientras se procesa la información.",
  payment_pending_information_2: "Este paso puede llevar unos minutos.",
  payment_cancelled: "Pago cancelado",
  payment_analysis_needed: "Información adicional requerida",
  payment_analysis_information_1:
    "Como parte de su solicitud de financiación, nos gustaría recopilar de su banco un historial de 90 días de sus transacciones",
  payment_analysis_information_2:
    "Este servicio es operado por nuestro socio {providerLabel}, líder en el intercambio seguro de datos bancarios, aprobado por la ACPR.",
  payment_analysis_information_3:
    "Al hacer clic en Validar, acepto transmitir a {operatorTeamName} la información extraída de mis extractos bancarios",
  payment_analysis_information_4:
    "Si no lo desea, puede volver a la selección de métodos de pago para {merchantName} haciendo clic en la cruz",
  payment_analysis_in_progress: "Análisis en curso",
  payment_analysis_progress_information_1:
    "Se está analizando su solicitud de una facilidad de pago.",
  payment_analysis_progress_information_2:
    "No actualice la página mientras se procesa la información. Este paso puede tardar unos minutos.",
  /** ----------------------------------------------------
   *                        SUCCESS
   *  ---------------------------------------------------- */
  congratulations: "¡Felicidades!",
  payment_registered: "Su pago ha sido registrado.",
  payment_method_registered: "Su nuevo método de pago ha sido tenido en cuenta.",
  /** ----------------------------------------------------
   *                        TWO-FA
   *  ---------------------------------------------------- */
  phone_number_check: "Comprobación de su número de teléfono",
  phone_number_tooltip: "Este es el número de teléfono que proporcionó al comerciante",
  wrong_code: "Código incorrecto, por favor, inténtelo de nuevo",
  unknown_error: "Se ha producido un error desconocido",
  code_valid: "El código es válido",
  please_type_code: "Escriba este código en el siguiente campo",
  you_ll_receive_text: "Acabamos de enviarle un código al ",
  did_not_receive: "No he recibido",
  send_again: "enviar otra vez",
  sending_sms: "Transmisión de código en curso",
  verify: "Verificar",
  code_transmission_error: "Ocurrió un error al enviar el código. intentar otra vez",
  /** ----------------------------------------------------
   *                        VALIDATIONS
   *  ---------------------------------------------------- */
  validations: {
    required: "El campo es obligatorio",
    phone_number: "El número telefónico es inválido",
    date_format: "El formato de fecha no es válido ({_value_})",
    invalid_birthdate: "Introduzca una fecha de nacimiento válida",
    date_between: "La fecha no es inválida",
    min: "El campo debe contener al menos {length} caracteres.",
    positive: "El valor debe ser un número positivo.",
    email: "El formato del correo electrónico no es válido ({_value_})",
  },
  /** ----------------------------------------------------
   *                        CUSTOM SCREEN
   *  ---------------------------------------------------- */
  custom_success_screen: {
    title: 'Su reserva no está completa hasta que haga clic en "Comprar ahora"',
    subtitle:
      "Una vez que tu reserva esté completa, tu viaje estará pagado en su totalidad y estarás listo para viajar",
    text: "Es posible que aparezca una preautorización en tu cuenta bancaria. Te avisaremos antes de que venza cada pago. El impago puede dar lugar a la cancelación del viaje.",
  },
  /** ----------------------------------------------------
   *                        ERROR SCREEN
   *  ---------------------------------------------------- */
  error_screen: {
    error_410: "El recurso solicitado ya no está disponible",
    error_404: "Error 404",
    expired: "La URL solicitada ha caducado",
    not_found: "No se ha encontrado la página buscada",
  },
};
