import httpService from "./httpService";

const ENDPOINTS = {
  geocoding_places: (merchantUid) =>
    `merchants/${merchantUid}/geocoding/autocomplete_address`,
  geocoding_place_details: (merchantUid) =>
    `merchants/${merchantUid}/geocoding/place_details`,
};

// see https://developers.google.com/maps/documentation/places/web-service/autocomplete#types
const SEARCH_TYPE = {
  CITY: "(regions)",
};

/**
 * Returns autocompleted addresses from an input string
 * @param {string} merchantUid The merchant uid
 * @param {string} inputString The input referring to a place
 * @param {string} language The language of the address to be returned
 * @param {string} sessionToken A unique id for api billing purposes
 */
async function getPlaces(merchantUid, inputString, language, sessionToken) {
  return httpService.get(ENDPOINTS.geocoding_places(merchantUid), {
    inputString,
    types: SEARCH_TYPE.CITY,
    language,
    sessionToken,
  });
}

/**
 * Returns address components from a google placeId
 * @param {string} merchantUid The merchant uid
 * @param {string} placeId A google placeId identifier
 * @param {string} language The language of the address to be returned
 * @param {string} sessionToken A unique id for api billing purposes
 */
async function getPlaceDetails(merchantUid, placeId, language, sessionToken) {
  return httpService.get(ENDPOINTS.geocoding_place_details(merchantUid), {
    placeId,
    language,
    sessionToken,
  });
}

const GeocodingService = {
  getPlaces,
  getPlaceDetails,
};

export default GeocodingService;
